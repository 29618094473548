import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    typography: {
        fontFamily: ['"Titillium Web"', 'Open Sans'].join(','),
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 400,
        "fontWeightBold": 600,
       }
})

export default theme;