import { Box, Button, CircularProgress, LinearProgress, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

const ElectionResults = () => {
  const [risultati, setRisultati] = useState<any>([]);
  const [totalVotes, setTotalVotes] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // axios
    //   .get(
    //     "https://evote.wakala.it/bulletin-board/v1/tally"
    //   )
    //   .then((res) => console.log('response get evote',res))
    //   .catch((err) => console.log('error response',err));

    axios
      .get(
        "https://evote.wakala.it/bulletin-board/v1/election_parameters?data=APP_DATA"
      )
      .then((res) => {
        let choices: any[] = [];
        console.log("response get APP DATA", res, res.data.blank_ballots[0]);
        choices = res.data.blank_ballots[0].choices;
        console.log("choices", choices);
        axios
          .get(
            "https://evote.wakala.it/bulletin-board/v1/election_parameters?data=ELECTION_RESULTS"
          )
          .then((res) => {
            console.log("response get ELECTION RESULTS", res, res.data);
            const regex = /(\d+) votes? for "(.*?)"[;]?/g;
            let votes = [];
    
            for (const match of res.data.matchAll(regex)) {
              votes.push({
                value: parseInt(match[1]),
                option: match[2]
              });
            }

            const total = votes.reduce((total, vote) => total + vote.value, 0);
            setTotalVotes(total);
            
            votes = votes.map((vote: any) => {
              const choiceIndex = ['abstain', 'approve', 'reject'].indexOf(vote.option);
              if (choiceIndex !== -1) {
                vote.option = choices[choiceIndex];
              }
              vote.percentage = ((vote.value / totalVotes) * 100).toFixed(2) + '%';
              return vote;
            });
    
            console.log('votes', votes);
            setRisultati(votes);
          })
          .catch((err) => console.log("error response", err));
      })
      .catch((err) => console.log("error response", err));
  }, []);

  const handleClick = () => {
    setLoading(true);
    axios
      .get("https://evote.wakala.it/bulletin-board/v1/tally")
      .then((res) => {
        console.log("response get evote con loader", res);
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        console.log("error response", err);
        setLoading(false);
      });
  };

  return (
    <>
      <div className="risultati-header-text-button">
        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
          className="bold-text"
        >
          RISULTATI
        </Typography>

        {risultati.length === 0 && (
        <Button
          sx={{ borderRadius: "24px", textTransform: "none" }}
          variant="contained"
          onClick={handleClick}
        >
          Chiudi Votazione
        </Button>
        )}
      </div>

      {loading ? (
          <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            width: '100%', 
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
              {risultati.length === 0 && (
        <Box className="cardResult">
          <Typography
            sx={{
              maxWidth: "300px",
            }}
            color="text.secondary"
            variant="h6"
            className="normal-text"
          >
            ELEZIONI IN CORSO
          </Typography>
        </Box>
      )}

      {risultati.length > 0 && (
        <Box className="cardResultWithData">
          <Typography
            sx={{
              maxWidth: "90%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              height: "100%",
            }}
            color="text.secondary"
            variant="h6"
            className="normal-text"
          >
            {risultati.map((el: any) => (
              <div key={el.id}>
                <div className="result-header-title">
                  <div>{el.option}</div>
                  <div>{el.value}</div>
                </div>
                <LinearProgress
                  sx={{
                    height: "18px",
                    borderRadius: "10px",
                    backgroundColor:
                      el.option === "Nessuna scelta" ? "#E3E7EA" : undefined,
                    "& .MuiLinearProgress-bar": {
                      backgroundColor:
                        el.option === "Nessuna scelta" ? "#5C6F82" : undefined,
                      borderRadius: "10px",
                    },
                  }}
                  variant="determinate"
                  value={(el.value / totalVotes) * 100}
                />
              </div>
            ))}

          </Typography>
        </Box>
      )}
        </>
      )}
    </>
  );
};


export default ElectionResults;
