import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

const NavBar = () => {
  return (
    <AppBar className="navBar" 
     position="static">
        <Toolbar>
          {/* <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} className="bold-text">
            IT Vote - Bacheca Elettorale Elettronica
          </Typography>
        </Toolbar>
    </AppBar>
  );
};

export default NavBar;