import {
  Container,
  Grid,
  // Box,
  // Button,
  // TextField,
  // Typography,
} from "@mui/material";
import NavBar from "../../components/AppBar";
import CardsRegistered from "../../components/CardsRegistered";
// import React, { useState } from "react";
// import ElectoralQuestion from "../../components/ElectoralQuestion";
// import ElectionResults from "../../components/ElectoralResults";
// import { useNavigate } from "react-router-dom";

const Home = () => {
  // const [username, setUsername] = useState("");
  // const [password, setPassword] = useState("");
  // const [errorMessage, setErrorMessage] = useState("");

  // const navigate = useNavigate();

  // const handleLogin = (event: React.FormEvent) => {
  //   event.preventDefault();

  //   if (username === "admin" && password === "admin") {
  //     setErrorMessage("");
  //     navigate("/dashboard");
  //   } else {
  //     setErrorMessage("Username o password non validi.");
  //   }
  // };

  return (
    <>
      <NavBar />
      <Container maxWidth="xl" sx={{ marginBottom: "48px" }}>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "0px",
            paddingBottom: "20px",
          }}
          container
          spacing={2}
        >
          <Grid item xs={12} md={4}>
            <CardsRegistered />
          </Grid>
          {/* <Grid  item xs={12} md={8}>

            <Box >
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <ElectoralQuestion />
                </Grid>
                <Grid  item xs={12} md={7}>
                  <ElectionResults /> 
                </Grid>
              </Grid>
            </Box>

          </Grid> */}
        </Grid>
      </Container>

      {/* <Container maxWidth="sm" className="login-section">
        <Typography variant="h4" sx={{display: "flex", justifyContent: "center"}}>
          Login
        </Typography>
        <Box sx={{ marginTop: 8, paddingBottom: "32px" }}>
          <form onSubmit={handleLogin} >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                >
                  Accedi
                </Button>
              </Grid>
            </Grid>
            {errorMessage && (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            )}
          </form>
        </Box>
      </Container> */}
    </>
  );
};

export default Home;
