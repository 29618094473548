import { Avatar, Box, Typography } from "@mui/material";
import avatar from "../assets/img/Avatar.png";
import { useEffect, useState } from "react";
import axios from "axios";

const ElectoralQuestion = () => {
  const [quesiti, setQuesiti] = useState<any>([]);
  useEffect(() => {
    axios
      .get(
        "https://evote.wakala.it/bulletin-board/v1/election_parameters?data=APP_DATA"
      )
      .then((res) => {
        console.log('electoral question',res);
        setQuesiti(res.data.blank_ballots);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <>
      <Typography variant="body1" component="div" sx={{ flexGrow: 1 }} className="bold-text">
        QUESITO ELETTORALE
      </Typography>
      {quesiti.length > 0 && (
        <Box className="cardQuestion">
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Avatar
              sx={{ width: 80, height: 80, margin: "auto" }}
              alt="Avatar"
              src={`data:image/png;base64, ${quesiti[0].image}`}
            />
            {/* <Typography variant="h6">Tiramisù:</Typography> */}
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h6"
              className="bold-text"
            >
              {quesiti[0].question}
            </Typography>
            {/* <Typography color="text.secondary" variant="body2" className="thin-text">
              Candidati:
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <Box>
                <Typography variant="body2" className="thin-text">Lista 1</Typography >
                <Typography variant="h6" color="primary" className="bold-text">
                  10
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" className="thin-text">Lista 2</Typography>
                <Typography variant="h6" color="primary" className="bold-text">
                  5
                </Typography>
              </Box>
            </Box> */}
          </Box>
        </Box>
      )}
    </>
  );
};

export default ElectoralQuestion;
