import {
  Box,
  IconButton,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect, useState } from "react";
import axios from "axios";

interface Scheda {
  digest_id: string;
  verified: boolean;
}

const CardsRegistered = () => {
  const [schede, setSchede] = useState<Scheda[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);

  const style = {
    display: "flex",
  };

  useEffect(() => {
    axios
      .get(
        "https://evote.wakala.it/bulletin-board/v1/election_parameters?data=all_digest_ids"
      )
      .then((res) => {
        setSchede(res.data);
        // console.log(res);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleCheckVote = () => {
    const found = schede.some((x) => x.digest_id === inputValue);
    if (found) {
      setResultMessage("Il voto risulta a sistema");
    } else {
      setResultMessage("Il voto non risulta a sistema");
    }
    setOpenPopUp(true);
  };

  const handleClosePopUp = () => {
    setOpenPopUp(false);
  };

  return (
    <>
      <Typography
        variant="h6"
        component="div"
        sx={{ flexGrow: 1 }}
        className="bold-text"
      >
        SCHEDE REGISTRATE
      </Typography>

      {/* {schede.map((obj: any) => (
        <Box
          sx={{
            maxHeight: "640px",
          }}
        >
          <Box className="cardList">
            {obj.verified === true && (
              <CheckCircleIcon className="iconCheck" color="primary" />
            )}

            <Typography
              sx={{
                maxWidth: "300px",
              }}
              variant="body2"
              className="normal-text"
            >
              {obj.digest_id}
            </Typography>
            <IconButton aria-label="settings">
              <KeyboardArrowRightIcon />
            </IconButton>
          </Box>
        </Box>
      ))} */}

      <div style={style}>
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          label="Inserisci l'id scheda"
          variant="outlined"
        />
        <IconButton onClick={handleCheckVote}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </div>

      <Dialog open={openPopUp} onClose={handleClosePopUp}>
        <DialogTitle>Risultato della Verifica</DialogTitle>
        <DialogContent>
          <Typography>{resultMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopUp}>Chiudi</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CardsRegistered;

//-KJarqJ8j7Ua?"?V3-B&
//Qa]yDneL&8G</e$Ks?]:
//FymLr*MRm3RM9$f<E3hG
//fZgx:fFfg-ryJy4*2i€W
//jGPMNJFiL:iW]3FaLq2R
//q*U?KU*odTGey?xF8Jmy
